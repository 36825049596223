import * as styleVars from '@common/styles/constants'

import { PillTheme } from '@common/components/pill'
import { UserStatusEnum } from '@generated/types.d'

export const statusToColorMapping = (
  status: string,
): {
  color: string
  backgroundColor: string
  theme: PillTheme
} => {
  switch (status) {
    case UserStatusEnum.Active:
      return {
        color: styleVars.green50,
        backgroundColor: styleVars.green10,
        theme: PillTheme.success,
      }

    case UserStatusEnum.Pending:
      return {
        color: styleVars.blue50,
        backgroundColor: styleVars.blue40a,
        theme: PillTheme.info,
      }

    case UserStatusEnum.Deactivated:
      return {
        color: styleVars.red50,
        backgroundColor: styleVars.red40a,
        theme: PillTheme.error,
      }

    default:
      console.error(`Provided status ${status} is currently not supported.`)
  }
}
