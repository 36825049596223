export const removeEmpty = (obj) => {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([, v]) => v != null)
      .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v]),
  )
}

export const getKeyByValue = (
  object: Record<string, string | number>,
  value: string | number,
) => {
  return Object.keys(object).find((key) => object[key] === value)
}
