import * as styleVars from '@common/styles/constants'

import { Theme, css } from '@emotion/react'
import { flip, offset, shift } from '@floating-ui/dom'
import { useCombinedRefs, useOverflowDetect } from '@common/utils/use-hooks'

import { Tooltip } from '../tooltip/v2'
import { classNames } from '@common/utils/helpers'
import styled from '@emotion/styled'

export enum PillTheme {
  default = 'default',
  success = 'success',
  info = 'info',
  warning = 'warning',
  error = 'error',
  custom = 'custom',
}

export type TPillTheme = keyof typeof PillTheme

interface PillProps extends React.HTMLAttributes<HTMLElement> {
  className?: string | undefined
  label?: string
  pillTheme?: TPillTheme
  customTheme?: Theme
  isRounded?: boolean
  tooltipEnabled?: boolean
  tooltipMaxWidth?: string
  labelWidth?: string
  labelCss?: any
}

type Pill = React.FC<React.PropsWithChildren<PillProps>>

const getThemeStyles = (pillTheme, customTheme) => {
  switch (pillTheme) {
    case PillTheme.success:
      return css`
        color: ${styleVars.green50};
        background-color: ${styleVars.green10};
      `

    case PillTheme.info:
      return css`
        color: ${styleVars.blue50};
        background-color: ${styleVars.blue10};
      `

    case PillTheme.warning:
      return css`
        color: ${styleVars.yellow50};
        background-color: ${styleVars.yellow10};
      `

    case PillTheme.error:
      return css`
        color: ${styleVars.red50};
        background-color: ${styleVars.red10};
      `

    case PillTheme.custom:
      return customTheme

    default:
      return css`
        color: ${styleVars.black};
        background-color: ${styleVars.fadedGrey};
      `
  }
}

const Pill: Pill = (props) => {
  const {
    className,
    label,
    pillTheme = 'default',
    labelCss,
    isRounded = false,
    labelWidth,
    tooltipMaxWidth = '300px',
    tooltipEnabled,
    customTheme,
    ...rest
  } = props
  const classes = classNames('pq-pill', className || '')

  const pillCss = [getThemeStyles(pillTheme, customTheme)]

  const [isOverFlowed, overflowRef] = useOverflowDetect()
  const ref = useCombinedRefs(overflowRef)

  return (
    <PillWrapper
      isRounded={isRounded}
      css={pillCss}
      className={classes}
      {...rest}
    >
      <Tooltip
        disableTooltip={!tooltipEnabled}
        enableHover={tooltipEnabled && !!isOverFlowed}
        role="tooltip"
        placement="top"
        middleware={[
          offset({
            mainAxis: 20,
            crossAxis: -80,
          }),
          flip(),
          shift(),
        ]}
      >
        <Tooltip.Trigger>
          <LabelWrapper
            css={labelCss}
            tooltipEnabled={tooltipEnabled}
            labelWidth={labelWidth}
            ref={ref}
          >
            {label}
          </LabelWrapper>
        </Tooltip.Trigger>
        <Tooltip.Content
          useArrow
          style={{
            maxWidth: tooltipMaxWidth,
          }}
        >
          {label}
        </Tooltip.Content>
      </Tooltip>
    </PillWrapper>
  )
}

const LabelWrapper = styled.div<PillProps>`
  ${({ tooltipEnabled, labelWidth }) =>
    tooltipEnabled
      ? css`
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          text-align: left;
          max-width: ${labelWidth};
          min-width: 100%;
        `
      : ''}
`

const PillWrapper = styled.div<PillProps>`
  display: inline-block;
  padding: 1em 1em;
  font-size: 10px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  margin: 0.15rem 0;

  ${(props: PillProps) =>
    props.isRounded
      ? css`
          border-radius: 10rem;
        `
      : ''}
`

export default Pill
